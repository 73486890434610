<template>
  <div>
    <form class="form" @submit.stop.prevent="formOnsubmit()">
      <!--begin: Wizard Step 1-->
      <div
        class="pb-5"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
          <span>
            {{ title }}
          </span>
        </h5>

        <b-form-group
          id="input-group-code"
          label="Kode:"
          label-for="input-code"
        >
          <b-form-input
            id="input-code"
            v-model="form.code"
            placeholder="Kode Posyandu"
          ></b-form-input>
          <small class="text-danger">{{ error.code }}</small>
        </b-form-group>

        <b-form-group
          id="input-group-name"
          label="Nama:"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="form.name"
            placeholder="Nama Posyandu"
          ></b-form-input>
          <small class="text-danger">{{ error.name }}</small>
        </b-form-group>

        <b-form-group
          id="input-group-total-population"
        >
        <label for="input-total-population">Jumlah Penduduk: <em class="text-muted">opsional</em></label>
          <b-form-input
            id="input-total-population"
            v-model="form.total_population"
            placeholder="Jumlah Penduduk"
          ></b-form-input>
          <small class="text-danger">{{ error.total_population }}</small>
        </b-form-group>

        <b-form-group
          id="input-group-ward"
          label="Kelurahan:"
          label-for="input-ward"
        >
          <b-form-input
            id="input-ward"
            v-model="form.ward"
            placeholder="Kelurahan"
          ></b-form-input>
          <small class="text-danger">{{ error.ward }}</small>
        </b-form-group>

        <b-form-group
          id="input-group-district"
          label="Kecamatan:"
          label-for="input-district"
        >
          <b-form-input
            id="input-district"
            v-model="form.districts"
            placeholder="Kecamatan"
          ></b-form-input>
          <small class="text-danger">{{ error.districts }}</small>
        </b-form-group>

        <b-form-group
          id="input-group-city"
          label="Kota/Kabupaten:"
          label-for="input-city"
        >
          <b-form-input
            id="input-city"
            v-model="form.city"
            placeholder="Kota/Kabupaten"
          ></b-form-input>
          <small class="text-danger">{{ error.city }}</small>
        </b-form-group>

        <b-form-group
          id="input-group-province"
          label="Provinsi:"
          label-for="input-province"
        >
          <b-form-input
            id="input-province"
            v-model="form.province"
            placeholder="Provinsi"
          ></b-form-input>
          <small class="text-danger">{{ error.province }}</small>
        </b-form-group>

        <b-form-group id="input-group-address">
          <label for="input-address">Alamat Lengkap:</label>
          <b-form-textarea
            id="input-address"
            v-model="form.address"
            placeholder="Alamat Lengkap Posyandu"
            rows="4"
            max-rows="8"
          ></b-form-textarea>
          <small class="text-danger">{{ error.address }}</small>
        </b-form-group>

      </div>
      <!--end: Wizard Step 1-->

      <!--begin: Wizard Actions -->
      <div class="d-flex justify-content-between border-top pt-10">
        <div>
          <b-button type="submit" variant="primary">Simpan</b-button>
          <b-button
            type="button"
            class="ml-2"
            variant="default"
            @click="$router.push('/masters/posyandu')"
          >
            Batal
          </b-button>
        </div>
      </div>
      <!--end: Wizard Actions -->
    </form>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
  },
  data() {
    return {
      error: {
        name: "",
        address: "",
        code: "",
        ward: "",
        districts: "",
        city: "",
        provinsi: ""
      },
    };
  },
  methods: {
    async formOnsubmit() {
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push('/masters/posyandu')
      }
    },
  },
};
</script>